import mutations from './mutations';
import actions from '@/store/settings/actions';

export const initialState = {
  toastList: [],
  popupList: [],
};

export default {
  state: {
    ...initialState,
  },
  actions,
  mutations,
};
