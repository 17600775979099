import {ADD_FAVORITE, DELETE_FAVORITE, GET_FAVORITE, GET_FAVORITE_EVENTS} from '../actionTypes';
import api from '../../api/api';
import {UPDATE_FAVORITES} from '../mutationTypes';

export default {
  [ADD_FAVORITE]: async ({dispatch}, {
    id,
    type,
  }) => {
    await api.favorites.add_favorite({
      id,
      type,
    });
    dispatch(GET_FAVORITE);
  },
  [DELETE_FAVORITE]: async ({dispatch}, {
    id,
  }) => {
    await api.favorites.delete_favorite({
      id,
    });
    dispatch(GET_FAVORITE);
  },
  [GET_FAVORITE]: async ({commit, getters}) => {
    if (!getters.isLogged) return Promise.reject(new Error('user must be logged in'));
    const res = await api.favorites.get_favorites();
    commit(UPDATE_FAVORITES, res);
    return res;
  },
  [GET_FAVORITE_EVENTS]: async (_,
      {
        abortSignal: token,
        ...params
      } = {}) => {
    return api.favorites.get_favorite_events(params, token);
  },
};
