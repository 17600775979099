import Vue from 'vue';

const directives = () => {
  Vue.directive('scroll', {
    // Самописная директива v-scroll которая вешает на елмент обработчик скролла окна
    inserted(el, binding) {
      const f = function(evt) {
        if (binding.value(evt, el) || !document.contains(el)) {
          window.removeEventListener('scroll', f);
          return;
        }
        binding.value(evt, el);
      };
      window.addEventListener('scroll', f);
    }});

  Vue.directive('scroll-el', {
    // Самописная директива v-scroll-el которая вешает на елмент обработчик скролла окна
    inserted(el, binding) {
      const f = function(evt) {
        if (binding.value(evt, el)) {
          el.removeEventListener('scroll', f);
          return;
        }
        binding.value(evt, el);
      };
      el.addEventListener('scroll', f);
    }});

  Vue.directive('focus', {
    // Когда привязанный элемент вставлен в DOM...
    inserted: function(el) {
      // Переключаем фокус на элемент
      el.focus();
    }});

  Vue.directive('click-outside', {
    bind: function(el, binding, vNode) {
      // Provided expression must evaluate to a function.
      if (typeof binding.value !== 'function') {
        const compName = vNode.context.name;
        let warn = `[Vue-click-outside:] provided expression 
        '${binding.expression}' is not a function, but has to be`;
        if (compName) {
          warn += `Found in component '${compName}'`;
        }

        console.warn(warn);
      }
      // Define Handler and cache it on the element
      const bubble = binding.modifiers.bubble;
      const handler = (e) => {
        if (bubble || (!el.contains(e.target) && el !== e.target)) {
          binding.value(e);
        }
      };
      el.__vueClickOutside__ = handler;

      // add Event Listeners
      document.addEventListener('click', handler);
    },

    unbind: function(el, binding) {
      // Remove Event Listeners
      document.removeEventListener('click', el.__vueClickOutside__);
      el.__vueClickOutside__ = null;
    }});

  return Vue;
};

export default directives();
