<template>
  <button
      @click.stop.prevent="handleClick"
      class="button"
        :title="$t(title)"
      :class="{
              [variant]: this.variant
            }"
      v-bind="$attrs"
  >
    <slot>
      {{ $t(title) }}
    </slot>
    <div class="disabled-overlay" v-if="$attrs.disabled" @click.stop.prevent="disabledClick"/>
  </button>
</template>

<script>
export default {
  name: 'ButtonComponent',
  props: {
    title: {
      type: String,
      default: 'Ok',
    },
    variant: {
      type: String,
      default: 'main',
    },
  },
  methods: {
    handleClick(e) {
      this.$emit('btn-click', e);
    },
    disabledClick() {
      this.$emit('disabled-click');
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/css/colors";

.button {
  position: relative;
  outline: none;
  border: none;
  padding: 0;
  border-radius: .5rem;
  transition: all .2s linear;
  font-weight: 900;
  text-transform: uppercase;
  cursor: pointer;

  &:disabled {
    filter: var(--disable-filter);
    cursor: auto;

    &:hover {
      box-shadow: none;
    }
  }
}

.main {
  --background: var(--theme-link-accent);
  --text: var(--settings-body-background);
  --shadow-color: var(--settings-body-text-color-20);
  background: var(--background);
  padding: .5rem 1rem;
  box-shadow: 0 0 0 var(--shadow-color);
  color: var(--text);
  &:hover {
    box-shadow: 3px 3px 6px 1px var(--shadow-color);
  }
}

.bordered{
  --border: var(--settings-footer-background);
  --shadow: var(--settings-footer-background-20);
  --text: var(--settings-body-text-color);
  padding: .5rem 1rem;
  background: transparent;
  border: 1px solid var(--border);
  color: var(--text);
  &:hover {
    box-shadow: 3px 3px 6px 1px var(--shadow);
  }
  &:disabled{
    background: transparent;
  }
}

.transparent {
  background: transparent;
  color: $text-primary;
  box-shadow: none;
  padding: .7rem 1rem;

  &:hover {
    box-shadow: none;
  }
}

.icon {
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }

  background: transparent;

  svg {
    color: var(--settings-body-text-color);
    width: 100%;
    height: 100%;
    display: flex;
  }
}

.disabled-overlay {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
</style>
