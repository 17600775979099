import auth from './models/auth-model.js';
import channel from './models/channel-model.js';
import event from './models/events-model';
import participant from './models/participant-model';
import faq from './models/faq-model';
import payment from './models/payment-model';
import customForm from './models/custom-form-model';
import vodMedia from './models/vod-media';
import geo from './models/geo-model';
import clipApi from './models/clip-model';
import favorites from './models/favorite-model';

export default {
  authApi: auth,
  channelsApi: channel,
  eventsApi: event,
  participantApi: participant,
  faqApi: faq,
  paymentApi: payment,
  customFormApi: customForm,
  vodMediaApi: vodMedia,
  geo: geo,
  clipApi: clipApi,
  favorites,
};
