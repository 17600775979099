import {PAGES_PATHS} from '@/pages';

export default [
  {
    ...PAGES_PATHS.HOME,
    meta: {
      title: 'Home',
      scrollToTop: true,
    },
    component: () => import(/* webpackChunkName: "home-view" */ './views/HomeScreen.vue'),
  },
  {
    ...PAGES_PATHS.PACKAGES,
    meta: {
      title: 'Packages',
      scrollToTop: true,
    },
    component: () => import(/* webpackChunkName: "schedule-view" */ './views/PackagesScreen.vue'),
  },
  {
    ...PAGES_PATHS.VOD_CLIPS,
    meta: {
      title: 'vod',
      scrollToTop: true,
    },
    component: () => import(/* webpackChunkName: "schedule-view" */ './views/VodLibraryScreen.vue'),
  },
  {
    ...PAGES_PATHS.WATCH,
    meta: {
      title: 'Watch',
    },
    component: () => import(/* webpackChunkName: "watch-view" */ './views/WatchGameScreen.vue'),
  },
  {
    ...PAGES_PATHS.SHARE,
    meta: {
      title: 'Share',
    },
    component: () => import(/* webpackChunkName: "share-view" */ './views/WatchShareScreen.vue'),
  },
  {
    ...PAGES_PATHS.EMBEDDED_WATCH,
    meta: {
      title: 'Watch Embed',
      plainLayout: true,
    },
    component: () => import(/* webpackChunkName: "watch-view" */ './views/WatchEmbedScreen.vue'),
  },
  {
    ...PAGES_PATHS.CLIPS_WATCH,
    meta: {
      title: 'Watch',
    },
    component: () => import(/* webpackChunkName: "watch-view" */ './views/WatchLibraryScreen.vue'),
  },
  {
    ...PAGES_PATHS.UPCOMING,
    meta: {
      title: 'Events',
      scrollToTop: true,
    },
    alias: [PAGES_PATHS.VOD.path, PAGES_PATHS.LIVE.path, PAGES_PATHS.ALL_EVENTS.path],
    component: () => import(/* webpackChunkName: "schedule-view" */ './views/EventsScreen.vue'),
  },
];
