import request from './request';
import {
  CLIP_API,
  CLIPS_FOR_EVENT_API,
  DOWNLOAD_CLIP_API, EVENTS_WITH_CLIP_API,
  SHARED_CLIPS_API,
  USER_CLIPS_API,
} from '@/urls';
import {API_METHODS} from '@/const';

export default {
  send_clip(params) {
    return request({
      url: CLIP_API,
      params,
      method: API_METHODS.POST,
    });
  },
  get_clips_for_id({id} = {}) {
    return request({
      url: `${CLIPS_FOR_EVENT_API}/${id}`,
      method: API_METHODS.GET,
    });
  },
  get_sharedclip(id) {
    return request({
      url: `${SHARED_CLIPS_API}/${id}`,
      method: API_METHODS.GET,
    });
  },
  downloadClip({id} = {}) {
    return request({
      url: `${DOWNLOAD_CLIP_API}/${id}`,
      method: API_METHODS.GET,
    });
  },
  delete_clip({id} = {}) {
    return request({
      url: `${CLIP_API}/${id}`,
      method: API_METHODS.DELETE,
    });
  },
  get_all_clips() {
    return request({
      url: USER_CLIPS_API,
      method: API_METHODS.GET,
    });
  },
  get_events_with_clips() {
    return request({
      url: EVENTS_WITH_CLIP_API,
      method: API_METHODS.GET,
    });
  },
};
