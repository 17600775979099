import api from '../../api/api';
import {
  GET_EVENTS_LIST,
  GET_EVENT,
  DOWNLOAD_EVENT,
  GET_EVENT_CATEGORIES,
  GET_HOME_PAGE_EVENTS,
  GET_USER_ACCESS,
} from '../actionTypes';
import {
  UPDATE_EVENTS_CATEGORIES,
} from '../mutationTypes';

export default {
  [GET_EVENTS_LIST]: async ({
    state,
    commit,
  },
  {
    abortSignal: token,
    ...params
  } = {}) => {
    const {
      requestParams = params,
      callbacks: {
        onSuccess,
        onFinally,
      } = {},
    } = params;

    const res = await api.eventsApi.get_events_list(requestParams, token);
    onSuccess?.(res);
    onFinally?.();
    return res;
  },
  [GET_HOME_PAGE_EVENTS]: async ({state, commit}, params = {}) => {
    return await api.eventsApi.get_home_page_events(params);
  },
  [GET_EVENT]: async (state, params) => {
    return await api.eventsApi.get_event(params);
  },
  [DOWNLOAD_EVENT]: async ({dispatch}, params) => {
    const downloadPromise = api.eventsApi.download_event(params);
    downloadPromise.finally(() => dispatch(GET_USER_ACCESS));
    return downloadPromise;
  },
  [GET_EVENT_CATEGORIES]: async ({
    state,
    commit,
  }, params) => {
    api.eventsApi.get_event_categories(params).then(({data = []}) => {
      commit(UPDATE_EVENTS_CATEGORIES, data);
    }).catch(() => {
    });
  },
};
