import request from './request';
import {FAQ_API} from '@/urls';
import {API_METHODS} from '@/const';

export default {
  get_faq(params = {}) {
    return request({
      url: FAQ_API,
      method: API_METHODS.GET,
      params,
    }).then((res) => res);
  },
};
