import mutations from './mutations';
import actions from './actions';

export const initialState = {
  clips: [],
};

export default {
  state: {
    ...initialState,
  },
  mutations,
  actions,
};
