import actions from './actions';

export const initialState = {};

export default {
  state: {
    ...initialState,
  },
  actions,
};
