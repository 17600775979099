import Vue from 'vue';
import axios from 'axios';
import UniversalSocialauth from 'universal-social-auth';
import {API} from '@/const';
const BASE_URL = API.API_HOST;

Vue.config.productionTip = false;

export const AUTH_OPTIONS = {
  providers: {
    google: {
      redirectUri: `${BASE_URL}/auth/login/google/callback`,
      state: 'api_key='+Vue.$storage.get('api_key'),
      optionalUrlParams: ['state'],
    },
    facebook: {
      redirectUri: `${BASE_URL}/auth/login/facebook/callback`,
      state: 'api_key='+Vue.$storage.get('api_key'),
      optionalUrlParams: ['state'],
    },
  },
};

const Oauth = new UniversalSocialauth(axios, AUTH_OPTIONS);

Vue.prototype.$axios = axios;
Vue.prototype.$Oauth = Oauth;
