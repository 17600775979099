import api from '../../api/api';
import {GET_CUSTOM_FORM_DATA} from '../actionTypes';
import {UPDATE_CUSTOM_FORM_DATA} from '../mutationTypes';

export default {
  [GET_CUSTOM_FORM_DATA]: async ({commit}, alias) => {
    commit(UPDATE_CUSTOM_FORM_DATA,
        {data: await api.customFormApi.get_custom_form(alias), alias: alias});
  },
};
