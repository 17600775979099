import * as MUTATION from '../mutationTypes';

export default {
  [MUTATION.UPDATE_LIST_PARAMS](state, data) {
    state.startListDate = data.start;
    state.endListDate = data.end;
  },
  [MUTATION.UPDATE_CALENDAR_PARAMS](state, data) {
    state.startCalendarDate = data + 86400;
  },
  [MUTATION.RESET_EVENT_PARAMS](state) {
    state.startCalendarDate = null;
    state.startListDate = null;
    state.endListDate = null;
  },
};
