import {
  UPDATE_EVENTS_CATEGORIES,
} from '../mutationTypes';
import {generateIdsObj} from '@/utils/helpers';

export default {
  [UPDATE_EVENTS_CATEGORIES](state, data) {
    state.eventCategories = data;
    state.eventCategoriesById = generateIdsObj(data);
  },
};
