import {
  UPDATE_PARTICIPANT_GROUP_LIST,
  UPDATE_PARTICIPANTS_LIST,
  UPDATE_PARTICIPANTS_PROPS,
} from '../mutationTypes';

export default {
  [UPDATE_PARTICIPANTS_LIST](state, data) {
    state.participantsList = [
      ...state.participantsList,
      ...data,
    ];
    state.participantsList.forEach((participant) => {
      state.participantsById[participant.id] = participant;
    });
  },
  [UPDATE_PARTICIPANT_GROUP_LIST](state, data) {
    state.participantGroups = data;
  },
  [UPDATE_PARTICIPANTS_PROPS](state, data) {
    state.participantsProps = data;
  },
};
