import Vue from 'vue';
import {LANGUAGES} from '@/const';
import dayjs from 'dayjs';
import * as MUTATIONS from '../mutationTypes';
import {createColorVariables} from '@/utils/colorScheme';
import GoogleAnalytics from '@/components/base-components/Analytics/GoogleAnalytics';
import {DEFAULT_LANGUAGE, SLIDES_TYPES, URL_QUERIES} from '@/const';
import VisaicV3Analytics from '@/components/base-components/Analytics/VisaicV3Analytics';

export default {
  [MUTATIONS.UPDATE_CHANNEL_SETTINGS](state, data) {
    state.settings = data.data;
    const lang = data.data.language;
    if (LANGUAGES[lang] && DEFAULT_LANGUAGE !== lang) {
      Vue.i18n.add(lang, LANGUAGES[lang]);
      Vue.i18n.set(lang);
      import(`dayjs/locale/${lang}.js`).then(() => {
        dayjs.locale(lang);
      });
    }
    if (data?.data?.['google-analytics']?.['google-tag']) {
      GoogleAnalytics.trackingId = data?.data?.['google-analytics']?.['google-tag'];
      GoogleAnalytics.currency = data?.data?.currency;
    }
    if (data?.data?.['yare-analytics']?.['yare-analytics-key']) {
      VisaicV3Analytics.profileHash = data?.data?.['yare-analytics']?.['yare-analytics-key'];
    }
    state.settings.remoteAuth = data?.data?.auth?.remote_auth ?
      Boolean(Number(data?.data?.auth?.remote_auth)) : false;
  },
  
  [MUTATIONS.UPDATE_CHANNEL_COLORS](state, data) {
    createColorVariables(data.data);
  },
  
  [MUTATIONS.UPDATE_CHANNEL_MENU](state, data) {
    state.menu = data.data;
  },
  [MUTATIONS.UPDATE_GROUP_MENU](state, groupsList) {
    const menu = [];
    groupsList.forEach((group) => {
      menu.push({
        name: group.name,
        custom_icon: group.image,
        id: group.id,
        alias: group.alias,
        url: `/?${URL_QUERIES.participantGroup}=${group.alias || group.name}`,
      });
    });
    state.groupMenu = menu;
  },
  [MUTATIONS.UPDATE_MAIN_CHANNEL_SLIDES](state, {data}) {
    data?.forEach((slide) => {
      if (slide.slidable_type) {
        if (!state.slides[slide.slidable_type][slide.slidable_id]) {
          state.slides[slide.slidable_type][slide.slidable_id] = [];
        }
        
        state.slides[slide.slidable_type][slide.slidable_id].push(slide);
      } else {
        state.slides[SLIDES_TYPES.main].push(slide);
      }
    });
    
    state.slides.requested = true;
  },
  [MUTATIONS.UPDATE_CHANNEL_PAGES](state, data) {
    state.pages = data.data.data;
  },
};
