import {loadScript} from '@/utils/helpers';

// GUIDE https://developers.google.com/analytics/devguides/collection/gtagjs/screens?hl=ru
// Enhanced ecommerce -
//    https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce?hl=ru
// eslint-disable-next-line
// Event parameters - https://support.google.com/analytics/answer/9143382?sjid=9744742990624214877-EU#zippy=%2C%D1%81%D0%BE%D0%B1%D1%8B%D1%82%D0%B8%D0%B5%2C%D1%8D%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%BD%D0%B0%D1%8F-%D1%82%D0%BE%D1%80%D0%B3%D0%BE%D0%B2%D0%BB%D1%8F
// Event parameters - https://developers.google.com/gtagjs/reference/ga4-events?hl=ru
// Structure = category > actions
// {
// category: 'video'
// actions: 'watch',
// label: 'CEDAR RAPIDS @ NTDP U17'
// }

const GA4_EVENTS = {
  config: 'config',
  login: 'login',
  page_path: 'page_path',
  page_view: 'page_view',
  view_buy_page: 'view_buy_page',
  buy_order: 'buy_order',
  view_item_list: 'view_item_list',
  select_item: 'select_item',
  add_to_cart: 'add to cart',
  begin_checkout: 'begin_checkout',
  purchase: 'purchase',
  purchase_success: 'purchase_success',
  purchase_failed: 'purchase_failed',
};

const TRACK_TYPE = {
  event: 'event',
  set: 'set',
};

const GA4_EVENT_CATEGORIES = {
  ecommerce: 'ecommerce',
  engagement: 'engagement',
  // you can add custom category
};

/**
 * Run Google Analytics
 */
class GTag {
  #gtagUrl = 'https://www.googletagmanager.com/gtag/js';
  #scriptLoaded = false;
  #trackID = null;
  #currency = 'USD';
  
  /**
   * @param {string} currency
   */
  set currency(currency) {
    this.#currency = currency;
  }
  
  /**
   * Install G-TAG id from admin for tracking
   * @param {string} id
   */
  set trackingId(id) {
    if (!id) return false;
    this.#trackID = id;
    this.#initScript();
  }
  
  /**
   * add events to Google data array to init track request
   */
  #track() {
    if (!this.#scriptLoaded) return;
    // eslint-disable-next-line
    window.dataLayer.push(arguments);
  }
  
  /**
   * Script loaded callback according to Google documentation
   */
  #scriptOnload() {
    this.#scriptLoaded = true;
    window.dataLayer = window.dataLayer || [];
    this.#track('js', new Date());
    this.#track(GA4_EVENTS.config, this.#trackID, {
      send_page_view: false,
      debug_mode: true,
    });
  }
  
  /**
   * Load Google Analytics script
   */
  #initScript() {
    const url = new URL(this.#gtagUrl);
    url.searchParams.set('id', this.#trackID);
    loadScript(url.href, () => this.#scriptOnload());
  }
  
  // TRACKING INTERFACE
  /**
   * track login event to GA
   * @param {string} method [Google|Facebook|site]
   */
  login(method) {
    this.#track(
        TRACK_TYPE.event,
        GA4_EVENTS.login,
        {'method': method},
    ); // method = Google, Facebook, site
  }
  
  /**
   * Track page load event
   * @param {string} page_title
   * @param {string} page_location
   * @param {string} page_path
   */
  pageView({
    page_title: title,
    page_location: location,
    page_path: path,
  }) {
    this.#track(TRACK_TYPE.set, GA4_EVENTS.page_path, path);
    this.#track(TRACK_TYPE.event, GA4_EVENTS.page_view, {
      page_title: title,
      page_location: location,
      page_path: path,
    });
  }
  
  /**
   * @param {object} typedList {type: list{array}}
   */
  viewItemList({typedList}) {
    const preparedItems = [];
    Object.entries(typedList).forEach?.(([type, list]) => {
      list?.forEach?.((item) => {
        preparedItems.push({
          item_id: item.id,
          item_name: item.name,
          currency: this.#currency,
          item_category: item.event_category_id,
          item_list_name: type,
          item_variant: type,
          price: parseFloat(item.base_price),
          quantity: 1,
        });
      });
    });
    
    this.#track(TRACK_TYPE.event, GA4_EVENTS.view_item_list, {
      item_list_name: 'Passes',
      items: preparedItems,
    });
  }
  
  /**
   * Opening the purchase page. Before downloading data;
   * @param {string} type;
   * @param {string|number} id;
   * @param {string} from - referrer page url;
   * @param {any} value;
   */
  viewBuyPage({
    type,
    id,
    from,
    value = 1,
  }) {
    this.#track(TRACK_TYPE.event, GA4_EVENTS.view_buy_page, {
      event_category: GA4_EVENT_CATEGORIES.ecommerce,
      event_label: `${type} - ${id}`,
      non_interaction: false,
      referrer_page: from,
      value,
    });
  }
  
  /**
   * After downloading information about the ordered product
   * @param {object} item - purchased item
   * @param {string} type - ORDER_TYPE|'event-ppv'
   * @param {string | number} id
   * @param {string} name - ordered item name
   * @param {string} from - referrer url
   */
  beginPurchase({
    item,
    type,
    id,
    name,
    from,
  }) {
    this.#track(TRACK_TYPE.event, GA4_EVENTS.add_to_cart, {
      currency: this.#currency,
      value: parseFloat(item.base_price),
      referrer_page: from,
      items: [
        {
          item_id: id,
          item_name: name,
          item_category: item.event_category_id,
          item_list_name: type,
          item_variant: type,
          price: parseFloat(item.base_price),
          quantity: 1,
        },
      ],
    });
  }
  
  /**
   * User starts entering card information
   * @param {object} item
   * @param {string} couponName
   * @param {string | number} discount
   * @param {string} type
   * @param {string | number} id
   * @param {string} name
   */
  beginCheckout({
    item,
    couponName,
    discount = 0,
    type,
    id,
    name,
  }) {
    this.#track(TRACK_TYPE.event, GA4_EVENTS.begin_checkout, {
      currency: this.#currency,
      value: parseFloat(item.base_price) - parseFloat(String(discount)),
      coupon: couponName,
      items: [{
        item_id: id,
        item_name: name,
        coupon: couponName,
        discount: parseFloat(String(discount)),
        item_variant: type,
        price: parseFloat(item.base_price),
        quantity: 1,
      }],
    });
  }
  
  /**
   *
   * @param {Object} item
   * @param {string} couponName
   * @param {string | number} discount
   * @param {string} type
   * @param {string | number} id
   * @param {string} name
   * @param {string} transaction_id
   * @param {string} tax
   * @param {string} error_reason
   * @return {{
   * transaction_id,
   * coupon,
   * currency: string,
   * tax: number,
   * value: number,
   * items: [{
   *    quantity: number,
   *    coupon,
   *    item_id,
   *    price: number,
   *    fee: number,
   *    discount: number,
   *    item_name,
   *    item_variant
   *  }]
   * }}
   */
  #preparePurchaseParams({
    item,
    couponName,
    discount = 0,
    type,
    id,
    name,
    transaction_id: transactionId,
    tax,
    error_reason: error,
  }) {
    const params = {
      currency: this.#currency,
      transaction_id: transactionId,
      value: parseFloat(item.base_price) - parseFloat(String(discount)) + parseFloat(tax),
      coupon: couponName,
      tax: parseFloat(tax),
      items: [
        {
          item_id: id,
          item_name: name,
          coupon: couponName,
          discount: parseFloat(String(discount)),
          item_variant: type,
          price: parseFloat(item.base_price),
          fee: parseFloat(item.service_fee),
          quantity: 1,
        },
      ],
    };
    if (error) {
      params.error_reason = error;
    }
    return params;
  }
  
  /**
   * Payment request sent
   * @param {object} item
   * @param {string} item.base_price
   * @param {string} item.service_fee
   * @param {string} couponName
   * @param {string | number} discount
   * @param {string} type
   * @param {string | number} id
   * @param {string} name
   * @param {string} transaction_id
   * @param {string} tax
   */
  processPurchase({
    item,
    couponName,
    discount = 0,
    type,
    id,
    name,
    transaction_id: transactionId,
    tax,
  }) {
    this.#track(TRACK_TYPE.event, GA4_EVENTS.purchase, this.#preparePurchaseParams({
      item,
      couponName,
      discount,
      type,
      id,
      name,
      transaction_id: transactionId,
      tax,
    },
    ));
  }
  
  /**
   * Payment request finished successfully
   * @param {object} item
   * @param {string} item.base_price
   * @param {string} item.service_fee
   * @param {string} couponName
   * @param {string | number} discount
   * @param {string} type
   * @param {string | number} id
   * @param {string} name
   * @param {string} transaction_id
   * @param {string} tax
   */
  successPurchase({
    item,
    couponName = '',
    discount = 0,
    type,
    id,
    name,
    transaction_id: transactionId,
    tax,
  }) {
    this.#track(TRACK_TYPE.event, GA4_EVENTS.purchase_success, this.#preparePurchaseParams({
      item,
      couponName,
      discount,
      type,
      id,
      name,
      transaction_id: transactionId,
      tax,
    }));
  }
  
  /**
   * Payment request finished successfully
   * @param {object} item
   * @param {string} item.base_price
   * @param {string} item.service_fee
   * @param {string} couponName
   * @param {string | number} discount
   * @param {string} type
   * @param {string | number} id
   * @param {string} name
   * @param {string} transaction_id
   * @param {string} tax
   * @param {string} error_reason
   */
  failedPurchase({
    item,
    couponName,
    discount = 0,
    type,
    id,
    name,
    transaction_id: transactionId,
    tax,
    error_reason: error,
  }) {
    this.#track(TRACK_TYPE.event, GA4_EVENTS.purchase_failed, this.#preparePurchaseParams({
      item,
      couponName,
      discount,
      type,
      id,
      name,
      transaction_id: transactionId,
      tax,
      error_reason: error,
    }));
  }
}

export default new GTag();
