import Vue from 'vue';
import {Vue2Storage} from 'vue2-storage';
// https://vcalendar.io/installation.html
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import 'bootstrap';
import store from '../store/store';
import vuexI18n from 'vuex-i18n';
import VueMeta from 'vue-meta';
import {MonthPicker, MonthPickerInput} from 'vue-month-picker';


Vue.component('date-picker', DatePicker);
Vue.use(VueMeta);
Vue.use(MonthPicker);
Vue.use(MonthPickerInput);
Vue.use(vuexI18n.plugin, store, {});

Vue.use(Vue2Storage, { // https://yarkovaleksei.github.io/vue2-storage/ru/
  prefix: 'rudy_', // store prefix
  ttl: 60 * 60 * 24 * 1000 * 360, // store time 360 days
});


