import {getAwayHomeTeams} from '@/utils/helpers';
import Vue from 'vue';


/**
 * Returns the away and home teams of an event.
 *
 * @param {Object} event - The event object.
 * @return {Object} - An object containing the away and home teams.
 */
export function eventTeams(event) {
  return getAwayHomeTeams(event);
}

/**
 * Returns the title of an event.
 * If the event has exactly two participants, the title is formatted as "awayTeam @ homeTeam".
 * Otherwise, the event's name is returned.
 *
 * @param {Object} event - The event object.
 * @return {string} - The title of the event.
 */
export function eventTitle(event) {
  if (event.participants?.length === 2) {
    const teams = eventTeams(event);
    return `${teams.awayTeam.shortName} @ ${teams.homeTeam.shortName}`;
  }
  return event.name;
}

/**
 * Returns an object containing the formatted date of the event.
 * The date is formatted into month, day, hour, and timezone.
 *
 * @param {Object} event - The event object.
 * @return {Object} - An object containing the formatted date of the event.
 */
export function eventTimeObject(event) {
  const {
    month,
    day,
    hh,
    zone,
  } = Vue.prototype.getFormattedDate({
    ts: event.event_utc_ts,
    timeZone: event?.time_zone || '',
    formatsOptions: {
      month: {month: 'short'},
      day: {day: '2-digit'},
      hh: {
        hour: '2-digit',
        hourCycle: 'h12',
        minute: '2-digit',
      },
    },
  });
  return {
    month,
    day,
    hh,
    zone,
  };
}
