import Vue from 'vue';

const filters = () => {
  Vue.filter('capplugins.jsitalize', function(value) {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
  });

  Vue.filter('uppercase', function(value) {
    if (!value) return '';
    return value.toUpperCase();
  });

  Vue.filter('lowercase', function(value) {
    if (!value) return '';
    return value.toLowerCase();
  });

  Vue.filter('getOneOf', Vue.prototype.getOneOf);

  return Vue;
};
export default filters();
