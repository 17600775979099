import request from './request';
import {CUSTOM_FORM_API} from '@/urls';
import {API_METHODS} from '@/const';

export default {
  get_custom_form(alias) {
    return request({
      url: `${CUSTOM_FORM_API}/${alias}`,
      method: API_METHODS.GET,
      config: {
        retryCount: 1,
        globalError: false,
        localError: false,
        retryDelay: 1000,
        crashCodes: ['5\\d\\d'],
        allowedCodes: [404],
      },
    })
        .then((res) => {
          return res;
        })
        .catch(() => {
        });
  },
};
