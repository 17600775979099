import {ADD_POPUP} from '@/store/actionTypes';
import {DELETE_POPUP, SET_POPUP_DATA} from '@/store/mutationTypes';
import {ERROR} from '@/const';

export default {
  async [ADD_POPUP]({state, commit}, data = {}) {
    const {
      component,
      ...attrs
    } = data;
    let res;
    let rej;
    
    const promise = new Promise((resolve, reject) => {
      res = resolve;
      rej = () => reject(new Error(ERROR.CANCELED));
    });
    
    const props = {
      component,
      ok: res,
      cancel: rej,
      attrs,
      currentIndex: state.popupList.length,
    };
    commit(SET_POPUP_DATA, {
      props,
    });
    promise
        .catch(() => {})
        .finally(() => {
          commit(DELETE_POPUP, props);
        });
    
    return promise;
  },
};
