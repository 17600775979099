import mutations from './mutations';
import actions from './actions';

export const initialState = {};

export default {
  state: {
    faq: [],
    ...initialState,
  },
  mutations,
  actions,
};
