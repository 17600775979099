import {FAVORITE_TYPES} from '@/const';

const fieldRequirements = {
  'favoritable_type': {
    types: ['string'],
    values: Object.values(FAVORITE_TYPES),
  },
};

export const validate = (paramsObject, successCb) => {
  const errors = [];

  Object.entries(paramsObject).forEach(([name, value]) => {
    if (!fieldRequirements[name]) return;
    const {types: allowedTypes, values: allowedValues} = fieldRequirements[name];
    if (allowedValues) {
      !allowedValues?.includes(value) && errors.push(
          `Field ${name} must be equal one of ${allowedValues}, but it's ${value}`,
      );
    }
    if (allowedTypes) {
      !allowedTypes.includes(typeof value) && errors.push(
          `Type of field ${name} must be equal one of ${allowedTypes},
          but it's ${typeof value} (${value})`,
      );
    }
  });
  
  return errors.length ?
      Promise.reject(new Error(JSON.stringify(errors))):
      successCb();
};
