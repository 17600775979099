import mutations from './mutations';
import actions from './actions';

export const initialState = {};

export default {
  state: {
    country: null,
    country_code: null,
    ip: null,
    ...initialState,
  },
  mutations,
  actions,
};
