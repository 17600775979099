import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const initialState = {
  customForm: {},
};

export default {
  state: {
    ...initialState,
  },
  mutations,
  actions,
  getters,
};
