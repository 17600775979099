import request from './request';
import {GEO_API} from '@/urls';
import {API_METHODS} from '@/const';

export default {
  get_geo_data: function(params = {}) {
    return request({
      url: GEO_API,
      method: API_METHODS.GET,
      params,
    }).then(({data}) => data);
  },
};
