import {ADD_TOAST, DELETE_POPUP, REMOVE_TOAST, SET_POPUP_DATA} from '../mutationTypes';

const DEFAULT_TOAST_DATA = {
  time: 5000,
  text: 'text',
  type: 'info',
};

export default {
  [ADD_TOAST](state, data = {}) {
    const newItem = {
      ...DEFAULT_TOAST_DATA,
      ...data,
      id: +(new Date()),
    };
    state.toastList = [...state.toastList, newItem];
  },
  [REMOVE_TOAST](state, data) {
    state.toastList = state.toastList.filter((toast) => toast !== data);
  },
  [SET_POPUP_DATA](state, {props}) {
    state.popupList = [
      ...state.popupList,
      props,
    ];
  },
  [DELETE_POPUP](state, data) {
    state.popupList = state.popupList.filter((popup) => popup !== data);
  },
};
