import Vue from 'vue';
import {UPDATE_GEO_DATA} from '../mutationTypes';

export default {
  [UPDATE_GEO_DATA](state, data) {
    if (data) {
      const {
        country,
        country_code,
        ip,
      } = data;
      const geoData = {
        country,
        country_code,
        ip,
      };
      Object.assign(state, geoData);
      Vue.$storage.set('geo', geoData);
    };
  },
};
