import request from './request';
import {PARTICIPANT_GROUP_API, PARTICIPANT_GROUP_PROPERTIES_API, PARTICIPANTS_API} from '@/urls';
import {API_METHODS} from '@/const';

export default {
  get_participants(params) {
    return request({
      url: PARTICIPANTS_API,
      method: API_METHODS.GET,
      params,
    });
  },
  get_participant_groups() {
    return request({
      url: PARTICIPANT_GROUP_API,
      method: API_METHODS.GET,
    });
  },
  get_participant_group_props() {
    return request({
      url: PARTICIPANT_GROUP_PROPERTIES_API,
      method: API_METHODS.GET,
    });
  },
};
